<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'

import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import type {
  SliceInterface,
  VoixTextFieldInterface,
  VoixSelectFieldInterface,
} from '@voix/types'
import Sandpiper from '~/layouts/Sandpiper.vue'


const props = defineProps({
  slice: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },
  fields: {
    type: Object as PropType<{
      title: VoixTextFieldInterface
      pageSpecialSlice: VoixSelectFieldInterface
    }>,
    required: true,
  },
})

defineSlice({
  name: {
    label: 'SandPiper Special Offer Slider',
    group: 'sandpiper',
    layouts: ['Sandpiper'],
  },
  preview: 'SlicesSandpiperSandpiperSpecialOfferSlider.jpg',
  fields: {
    title: { label: 'Title', type: 'text', name: 'title' },
  },
  slots: [],
})


const pageStore = usePageStore()
const currentPage = pageStore.currentPage
const { data: pageSpecials } = await useVoixAsyncData(
  `/api/playa/page-specials/${currentPage.id}/SandpiperSpecialSlide`,
)
</script>

<template>
  <div class="py-12 flex flex-col items-center">
    <div class="pb-8 text-3xl text-center font-sandpiper-display font-medium font-midnight">
      {{ fields.title.value }}
    </div>
    <ClientOnly>
      <Swiper
        v-if="pageSpecials?.length > 0" slides-per-view="auto"
        :modules="[Pagination, Navigation]"
        :space-between="30"
        :centered-slides="pageSpecials.length <= 1"
        :pagination="{ clickable: true }"
        :navigation="pageSpecials.length >= 1"
        :free-mode="{ enabled: false }"
        style="margin:0"
        class=" max-w-[100vw]"
      >
        <SwiperSlide
          v-for="(special, key) in pageSpecials"
          :key="key"
          class="md:mx-24  max-w-[1000px]"
        >
        <SandpiperSpecialSlide
            :index="key"
            :special="special"
          />
        </SwiperSlide>
      </Swiper>
    </ClientOnly>
  </div>
</template>

<style scoped>
</style>

<style>
:root {
  --swiper-theme-color: #CA9117;
  --swiper-pagination-bottom: 10px;
}
</style>
